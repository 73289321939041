import {
  queryGiftCards,
  queryGiftCardDetail,
  queryGiftCardTransactions,
  deleteGiftCard,
  updateGiftCardStatus,
  updateGiftCardNote,
  queryGiftCardTopups,
} from '../services/giftcard';
import moment from 'moment';
import {
  GIFT_CARD_TRANSACTION_TYPE_MAPPING,
  GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE,
  GIFT_CARD_TRANSACTION_TYPE_PURCHANSE,
  GIFT_CARD_TRANSACTION_TYPE_REFUNDED,
} from '../consts';
import { TAB_KEY_RESTAURANTGROUP, TAB_KEY_RESTAURANT } from 'src/components/Loyalty/GiftcardTabs/tabKeyConst';
const BASE = 'GIFTCARD';

export const actions = {
  loadingGiftCards: `${BASE}_LOADING_GIFTCARDS`,
  loadGiftCardsSuccess: `${BASE}_LOAD__GIFTCARDS_SUCCESS`,
  loadGiftCardsFailed: `${BASE}_LOAD__GIFTCARDS_FAILED`,

  updateGiftCard: `${BASE}_UPDATE_GIFTCARD`,

  loadingGiftCardTranscationList: `${BASE}_LOADING_GIFTCARD_TRANSACTION_LIST`,
  loadGiftCardTranscationListSuccess: `${BASE}_LOAD__GIFTCARD_TRANSACTION_LIST_SUCCESS`,
  loadGiftCardTranscationListFailed: `${BASE}_LOAD__GIFTCARD_TRANSACTION_LIST_FAILED`,

  loadingGiftCardTopups: `${BASE}_LOADING_GIFTCARD_TOPUPS`,
  loadGiftCardTopupsSuccess: `${BASE}_LOAD__GIFTCARD_TOPUPS_SUCCESS`,
  loadGiftCardTopupsFailed: `${BASE}_LOAD__GIFTCARD_TOPUPS_FAILED`,
};

export const fetchGiftCards = (params, callback) => async (dispatch, getState) => {
  const restaurantInfo = getState().setting.restaurantInfo;
  const restaurantTimeZone = restaurantInfo.time_zone;
  const restaurantGroupInfo = getState().user.restaurantGroupInfo;
  const restaurantGroupGiftCardDealContract = getState().user.restaurantGroupGiftCardDealContract;
  let restaurant_group_id = '';
  if (
    restaurantGroupInfo &&
    restaurantGroupInfo.id &&
    restaurantGroupGiftCardDealContract &&
    params.scope === TAB_KEY_RESTAURANTGROUP
  ) {
    restaurant_group_id = restaurantGroupInfo.id;
  }

  !callback &&
    dispatch({
      type: actions.loadingGiftCards,
    });

  let response;

  try {
    response = await queryGiftCards(
      Object.assign({}, params, {
        time_zone: restaurantTimeZone || 'Central Time (US & Canada)',
        restaurant_group_id,
      })
    );
  } catch (e) {
    response = { success: false };
  }
  let pageSize = Number.parseInt(params.page_size) || 10;
  let cuurentPage = Number.parseInt(params.page) || 1;

  if (!response) {
    !callback &&
      dispatch({
        type: actions.loadGiftCardsFailed,
      });
    callback && callback(null);
    return;
  }

  const { data, meta: pageData } = response.data || {};
  const { page = cuurentPage, page_size = pageSize, total = 0 } = pageData || {};
  const convertedData = (data || []).map((item) => {
    let { attributes, id } = item;
    let {
      customer_id,
      created_at,
      initial_balance,
      issuer,
      lifetime_spending,
      note,
      status,
      total,
      updated_at,
      vendor_id: restaurant_id,
    } = attributes;
    return {
      id: id,
      card_number: customer_id,
      issue_time: moment(created_at).unix(),
      initial_balance: initial_balance ? Number.parseFloat(initial_balance) * 100 : 0,
      current_balance: total ? Number.parseFloat(total) * 100 : 0,
      total_spending: lifetime_spending ? Number.parseFloat(lifetime_spending) * 100 : 0,
      last_update: moment(updated_at).unix(),
      status: status,
      note: note || '',
      issuer: issuer,
    };
  });

  !callback &&
    dispatch({
      type: actions.loadGiftCardsSuccess,
      payload: {
        giftCards: convertedData,
        pagination: {
          current: page,
          pageSize: page_size,
          total,
        },
      },
    });
  callback &&
    callback({
      giftCards: convertedData,
      pagination: {
        current: page,
        pageSize: page_size,
        total,
      },
    });
};

export const fetchGiftCardTransactionList =
  ({ giftcard_id, page: pageIn, page_size: page_size_in }) =>
  async (dispatch, getState) => {
    dispatch({
      type: actions.loadingGiftCardTranscationList,
    });

    const restaurantInfo = getState().setting.restaurantInfo;
    const restaurantGroupInfo = getState().user.restaurantGroupInfo;
    let restaurant_group_id = '';
    if (restaurantGroupInfo && restaurantGroupInfo.id) {
      restaurant_group_id = restaurantGroupInfo.id;
    }

    let response;

    try {
      response = await queryGiftCardTransactions({
        giftcard_id,
        page: pageIn,
        page_size: page_size_in,
        restaurant_group_id,
        restaurant_id: restaurantInfo.id,
      });
    } catch (e) {
      response = { success: false };
    }

    let pageSize = Number.parseInt(page_size_in) || 10;
    let cuurentPage = Number.parseInt(pageIn) || 1;

    if (!response) {
      dispatch({
        type: actions.loadGiftCardTranscationListFailed,
      });
      return;
    }

    const { data, meta: pageData } = response.data || {};
    const { page = cuurentPage, page_size = pageSize, total = 0 } = pageData || {};
    const convertedData = (data || []).map((item) => {
      let { attributes, id } = item;
      let {
        bonus_amount,
        cash_amount,
        created_at,
        last4,
        order_number,
        payment_type,
        staff,
        transaction_type, //"recharge"
        balance,
      } = attributes;
      // bonus_amount = Number.parseFloat(bonus_amount) * 100 * (transaction_type === GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE ? 1 : -1);
      // cash_amount = Number.parseFloat(cash_amount) * 100 * (transaction_type === GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE ? 1 : -1);
      bonus_amount = Number.parseFloat(bonus_amount) * 100;
      cash_amount = Number.parseFloat(cash_amount) * 100;
      let totalAmount = bonus_amount + cash_amount;
      return {
        id: id,
        time: moment(created_at).unix(),
        type: transaction_type,
        staff: staff,
        purchase_amount: transaction_type === GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE ? cash_amount : totalAmount,
        bonus_amount: transaction_type === GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE ? bonus_amount : '',
        balance: balance ? Number.parseFloat(balance) * 100 : 0,
        payment_type: payment_type || '',
        payment_card_type: payment_type || '',
        payment_last_4: last4 || '',
        payment_total: totalAmount,
        order_number: order_number || '',
      };
    });
    dispatch({
      type: actions.loadGiftCardTranscationListSuccess,
      payload: {
        transactions: convertedData,
        transactionPagination: {
          current: page,
          pageSize: page_size,
          total,
        },
      },
    });
  };

export const fetchGiftCardDetail =
  ({ id, callback }) =>
  async (dispatch) => {
    let response;

    try {
      response = await queryGiftCardDetail(id);
    } catch (e) {
      response = { success: false };
    }

    if (response.success) {
      const { data } = response.data || {};
      let { attributes, id } = data;
      let {
        customer_id,
        created_at,
        initial_balance,
        issuer,
        lifetime_spending,
        note,
        status,
        total,
        updated_at,
        vendor_id: restaurant_id,
      } = attributes;
      let convertedData = {
        id: id,
        card_number: customer_id,
        issue_time: moment(created_at).unix(),
        initial_balance: initial_balance ? Number.parseFloat(initial_balance) * 100 : 0,
        current_balance: total ? Number.parseFloat(total) * 100 : 0,
        total_spending: lifetime_spending ? Number.parseFloat(lifetime_spending) * 100 : 0,
        last_update: moment(updated_at).unix(),
        status: status,
        note: note || '',
        issuer: issuer,
      };

      response.data = convertedData;
    }
    typeof callback === 'function' && callback(response);
  };

export const doDeleteGiftCard = (id, callback) => async () => {
  let response;

  try {
    response = await deleteGiftCard({ giftcard_id: id });
  } catch (e) {
    response = { success: false };
  }
  typeof callback === 'function' && callback(response);
};

export const doUpdateGiftCardStatus = (id, status, callback) => async () => {
  let response;

  try {
    response = await updateGiftCardStatus({ giftcard_id: id, status });
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const doUpdateGiftCardNotes = (id, note, callback) => async () => {
  let response;

  try {
    response = await updateGiftCardNote({ giftcard_id: id, note });
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const fetchGiftCardTopupRecords = (params, callback) => async (dispatch, getState) => {
  const restaurantInfo = getState().setting.restaurantInfo;
  const restaurantTimeZone = restaurantInfo.time_zone;
  const restaurantGroupInfo = getState().user.restaurantGroupInfo;
  const restaurantGroupGiftCardDealContract = getState().user.restaurantGroupGiftCardDealContract;
  let restaurant_group_id = '';
  if (
    restaurantGroupInfo &&
    restaurantGroupInfo.id &&
    restaurantGroupGiftCardDealContract &&
    params.scope === TAB_KEY_RESTAURANTGROUP
  ) {
    restaurant_group_id = restaurantGroupInfo.id;
  }
  !callback &&
    dispatch({
      type: actions.loadingGiftCardTopups,
    });

  let response;
  try {
    response = await queryGiftCardTopups(
      Object.assign({}, params, {
        time_zone: restaurantTimeZone || 'Central Time (US & Canada)',
        restaurant_group_id,
      })
    );
  } catch (e) {
    response = { success: false };
  }
  let pageSize = Number.parseInt(params.page_size) || 10;
  let cuurentPage = Number.parseInt(params.page) || 1;

  if (!response) {
    !callback &&
      dispatch({
        type: actions.loadGiftCardTopupsFailed,
      });
    callback &&
      callback({
        success: false,
        data: null,
      });
    return;
  }

  const { data, meta: pageData } = response.data || {};
  const { page = cuurentPage, page_size = pageSize, total = 0 } = pageData || {};
  const convertedData = (data || []).map((item) => {
    let { attributes, id } = item;
    let {
      bonus_amount,
      cash_amount,
      created_at,
      last4,
      order_number,
      payment_type,
      staff,
      transaction_type, //"recharge"
      balance,
      card_number,
    } = attributes;
    bonus_amount = Number.parseFloat(bonus_amount) * 100;
    cash_amount = Number.parseFloat(cash_amount) * 100;
    let totalAmount = bonus_amount + cash_amount;

    return {
      id: id,
      time: moment(created_at).unix(),
      type: transaction_type,
      staff: staff,
      purchase_amount: transaction_type === GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE ? cash_amount : totalAmount,
      bonus_amount: transaction_type === GIFT_CARD_TRANSACTION_TYPE_ADD_VALUE ? bonus_amount : '',
      balance: balance ? Number.parseFloat(balance) * 100 : 0,
      payment_type: payment_type || '',
      payment_card_type: payment_type || '',
      payment_last_4: last4 || '',
      payment_total: totalAmount,
      order_number: order_number || '',
      card_number,
    };
  });
  callback &&
    callback({
      success: true,
      data: {
        giftCardTopups: convertedData,
        giftCardTopupsPagination: {
          current: page,
          pageSize: page_size,
          total,
        },
      },
    });

  !callback &&
    dispatch({
      type: actions.loadGiftCardTopupsSuccess,
      payload: {
        giftCardTopups: convertedData,
        giftCardTopupsPagination: {
          current: page,
          pageSize: page_size,
          total,
        },
      },
    });
};
