import { DELETE, GET, PUT } from '../utils/request';
import { GIFTCARD_API_PREFIX } from '../consts';
import moment from 'moment';
import { FORMAT_WITH_HHMMSS } from '../consts';

export function queryGiftCards({
  restaurant_group_id,
  restaurant_id,
  sort_field = '',
  sort_order = '',
  search_card = '',
  start_at = '',
  end_at = '',
  page = 1,
  page_size = 10,
  time_zone = 'Central Time (US & Canada)',
}) {
  const _FORMAT_WITH_HHMMSS_ = 'YYYY/MM/DDTHH:mm:ss';
  let _sort_by_ = sort_order && sort_field ? `${sort_field}/${sort_order === 'ascend' ? 'asc' : 'desc'}` : '';
  return GET(
    `${GIFTCARD_API_PREFIX}/wallet/api/gift_card/backend/cards`,
    {
      restaurant_id,
      card_number: search_card,
      sort_by: _sort_by_,
      date: `${moment(start_at, FORMAT_WITH_HHMMSS).format(_FORMAT_WITH_HHMMSS_)}-${moment(
        end_at,
        FORMAT_WITH_HHMMSS
      ).format(_FORMAT_WITH_HHMMSS_)}-${time_zone}`,
      page,
      page_size,
      ...(restaurant_group_id
        ? {
            restaurant_group_id,
          }
        : {}),
    },
    {
      silence: true,
    }
  );
}

export function queryGiftCardDetail(giftCardId) {
  return GET(
    `${GIFTCARD_API_PREFIX}/wallet/api/gift_card/backend/cards/${giftCardId}`,
    {},
    {
      silence: true,
    }
  );
}
export function queryGiftCardTransactions({
  restaurant_group_id,
  restaurant_id,
  giftcard_id,
  page = 1,
  page_size = 10,
}) {
  return GET(
    `${GIFTCARD_API_PREFIX}/wallet/api/gift_card/backend/cards/${giftcard_id}/transactions`,
    {
      page,
      page_size,
      ...(restaurant_group_id
        ? {
            restaurant_group_id,
            restaurant_id,
          }
        : {}),
    },
    {
      silence: true,
    }
  );
}

export function deleteGiftCard({ giftcard_id }) {
  return DELETE(`${GIFTCARD_API_PREFIX}/wallet/api/gift_card/backend/cards/${giftcard_id}`, {});
}

export function updateGiftCardStatus({ giftcard_id, status }) {
  return PUT(`${GIFTCARD_API_PREFIX}/wallet/api/gift_card/backend/cards/${giftcard_id}`, {
    status,
  });
}

export function updateGiftCardNote({ giftcard_id, note }) {
  return PUT(`${GIFTCARD_API_PREFIX}/wallet/api/gift_card/backend/cards/${giftcard_id}`, {
    note,
  });
}

export function queryGiftCardTopups({
  restaurant_group_id,
  restaurant_id,
  sort_field = '',
  sort_order = '',
  search_card = '',
  start_at = '',
  end_at = '',
  page = 1,
  page_size = 10,
  time_zone = 'Central Time (US & Canada)',
}) {
  const _FORMAT_WITH_HHMMSS_ = 'YYYY/MM/DDTHH:mm:ss';
  let _sort_by_ = sort_order && sort_field ? `${sort_field}/${sort_order === 'ascend' ? 'asc' : 'desc'}` : '';
  return GET(
    `${GIFTCARD_API_PREFIX}/wallet/api/gift_card/backend/recharge_records`,
    {
      restaurant_id,
      card_number: search_card,
      sort_by: _sort_by_,
      date: `${moment(start_at, FORMAT_WITH_HHMMSS).format(_FORMAT_WITH_HHMMSS_)}-${moment(
        end_at,
        FORMAT_WITH_HHMMSS
      ).format(_FORMAT_WITH_HHMMSS_)}-${time_zone}`,
      page,
      page_size,
      ...(restaurant_group_id
        ? {
            restaurant_group_id,
          }
        : {}),
    },
    {
      silence: true,
    }
  );
}
